import MuiSkeleton from "@mui/material/Skeleton";
import { SxProps } from "@mui/system";

type TSkeleton = {
  variant?: "text" | "rectangular" | "circular";
  width?: number | string;
  height?: number | string;
  sx?: SxProps;
}

function Skeleton(props: Partial<TSkeleton>) {
  const { variant, sx, ...rest } = props;

  return (
    <MuiSkeleton
      variant={variant}
      sx={sx}
      {...rest}
    />
  );
};

export default Skeleton;
